import React, { useCallback, useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { postEvent } from '../utils/api';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import * as Sentry from '@sentry/react';
import history from '../utils/history';

// components
import Layout, { LayoutV2 } from './Layout';
import PageTitle from './PageTitle';
import ErrorBoundry from '../utils/ErrorBoundary';

// pages
import ErrorPage from '../pages/error';
import Login from '../pages/login';
import Signup from '../pages/signup/Signup';
import Reset from '../pages/reset';
import Unsubscribe from '../pages/unsubscribe/Unsubscribe';

// context
import { fetchUser, refreshToken, signOut } from '../store/effects/user';
import { RootState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { selectAgency } from '../store/slices/userSlice';

import { useFeatureEnabled, KNOWN_FEATURES } from '../context/FeatureProvider';
import useCookie from '../utils/hooks/useCookie';

export default function App() {
  // individually selected because the entire app will rerender when the tokens are updated otherwise
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  const { isCleanseOnly } = useSelector(selectAgency);

  const enableMerge = useFeatureEnabled(KNOWN_FEATURES.SHOW_MERGE_REDESIGN);
  const { cookieValue } = useCookie('MERGE_REDESIGN_OPT_IN');
  const showMerge = enableMerge && cookieValue === 'true';

  const dispatch = useDispatch();
  const [userRefreshed, setUserRefreshed] = useState(false);
  const refreshUser = useCallback(() => {
    dispatch(refreshToken());
    dispatch(
      fetchUser(
        ({ user_id: id, email }) => {
          setUserRefreshed(true);
          Sentry.setUser({ id, email });
        },
        (response: Response) => {
          if (response.status === 401) {
            dispatch(signOut());
          }
        }
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (window.performance) {
      if (isAuthenticated) {
        if (performance.navigation.type === 1) {
          postEvent('app_reload');
          refreshUser();
          const agencyId = sessionStorage.getItem('agency_id');
          const userRole = sessionStorage.getItem('user_role');
          sessionStorage.clear();
          agencyId && sessionStorage.setItem('agency_id', agencyId);
          userRole && sessionStorage.setItem('user_role', userRole);
        } else {
          refreshUser();
          postEvent('app_open');
        }
      }
    }
  }, [isAuthenticated, refreshUser]);

  window.addEventListener('beforeunload', (ev) => {
    if (isAuthenticated && performance.navigation.type !== 1) {
      postEvent('app_close');
    }
  });

  return (
    <ErrorBoundry>
      <PageTitle />
      {userRefreshed || !isAuthenticated ? (
        <>
          <CleanseOnlyRedirect isCleanseOnly={isCleanseOnly} />
          <Switch>
            <PublicRoute path='/login' isAuthenticated={isAuthenticated}>
              <Login />
            </PublicRoute>
            <PublicRoute path='/signup' isAuthenticated={isAuthenticated}>
              <Signup />
            </PublicRoute>
            <PublicRoute path='/reset' isAuthenticated={isAuthenticated}>
              <Reset history={history} />
            </PublicRoute>
            <PublicRoute path='/unsubscribe'>
              <Unsubscribe />
            </PublicRoute>
            {!showMerge && !isCleanseOnly && (
              <Route exact path='/' render={() => <Redirect to='/system' />} />
            )}
            <PrivateRoute path='/' component={showMerge ? LayoutV2 : Layout} />
            <Route component={ErrorPage} />
          </Switch>
        </>
      ) : (
        <div style={{ height: '100vh' }}>
          <LoadingSpinner message={'App Loading...'} showError={false} />
        </div>
      )}
    </ErrorBoundry>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }: RouteProps) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            component && React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({
    children,
    isAuthenticated,
    ...rest
  }: { isAuthenticated?: boolean } & RouteProps) {
    return (
      <Route
        {...rest}
        render={() =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  function CleanseOnlyRedirect({ isCleanseOnly }: { isCleanseOnly?: boolean }) {
    const location = useLocation();
    if (isCleanseOnly && !location.pathname.match(/settings/)) {
      return <Redirect to='/settings' />;
    }
    return null;
  }
}
