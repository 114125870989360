import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAgency } from '../../store/slices/userSlice';
import { Chat, ChatOutlined } from '@material-ui/icons';
import HeaderMenuButton from '../HeaderMenuButton';
import HeaderMenuButtonV2 from '../HeaderMenuButton/HeaderMenuButtonV2';
import { makeStyles } from '@material-ui/styles';
import { usePostTrackingEventMutation } from '../../store/slices/apiSlice';

const useStyles = makeStyles(() => ({
  chatIcon: {
    color: 'secondary',
  },
}));

type ChatButtonProps = { useV2?: boolean };

export default function ChatButton({ useV2 }: ChatButtonProps) {
  const [chatActive, setChatActive] = useState(false);
  const classes = useStyles();
  const { agencyId } = useSelector(selectAgency);
  const [postEvent] = usePostTrackingEventMutation();

  useEffect(() => {
    window.zE?.('webWidget:on', 'close', () => {
      window.zE?.('webWidget', 'hide');
      setChatActive(false);
    });
  }, []);

  const onChatButtonClick = useCallback(
    (isActive) => {
      if (!isActive) {
        window.zE?.('webWidget', 'hide');
      } else {
        window.zE?.('webWidget', 'show');
        window.zE?.('webWidget', 'open');
        postEvent({ agencyId, name: 'support_chat_opened' });
      }
      setChatActive(isActive);
    },
    [agencyId, postEvent]
  );

  const Button = useV2 ? HeaderMenuButtonV2 : HeaderMenuButton;

  return (
    <Button
      aria-controls='profile-help'
      aria-haspopup='true'
      aria-label='Help'
      className=''
      color='inherit'
      id='help_button'
      isActive={chatActive}
      title='Help'
      onClick={() => onChatButtonClick(!chatActive)}
    >
      {chatActive ? (
        <Chat fontSize='inherit' classes={{ root: classes.chatIcon }} />
      ) : (
        <ChatOutlined fontSize='inherit' classes={{ root: classes.chatIcon }} />
      )}
    </Button>
  );
}
